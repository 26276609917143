import React, { useContext } from "react";
import { UserAuthContext } from "../../context";

const ProtectComponent = ({ children }) => {
  const { isAuthenticated } = useContext(UserAuthContext);

  return <>{isAuthenticated && <>{children}</>}</>;
};

export default ProtectComponent;
