import React, { useContext, useEffect } from "react";
import { UserAuthContext } from "../../context";
import PropTypes from "prop-types";
import { useNavigate } from "../../hooks";

const ProtectComponentAfterSingin = ({ children, location, redirectPath }) => {
  const { isAuthenticated } = useContext(UserAuthContext);
  const navigate = useNavigate();

  const { state: routeState } = location;
  const redirect = !routeState
    ? `/`
    : !routeState.redirect
    ? `/`
    : `${routeState.redirect}`;

  useEffect(() => {
    if (!isAuthenticated) return;

    navigate(redirectPath || redirect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <>{!isAuthenticated && <>{children}</>}</>;
};

ProtectComponentAfterSingin.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
};

export default ProtectComponentAfterSingin;
