import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import { useForgotPassword } from "../mutations";
import produce from "immer";
import _ from "lodash";
import { ProtectComponentAfterSingin } from "../components/ProtectComponents";
import { useAlert } from "react-alert";
import { useTrans } from "../hooks";

// ====================================================

const ForgotPassword = ({ pageContext, location }) => {
  const { mainPhoto, seoData, layoutProps } = pageContext;
  const transMsg = useTrans();
  const alert = useAlert();

  const [enableSection, setEnableSection] = useState(false);

  const { data, error, setData: setVariable } = useForgotPassword();

  const [formData, setFormData] = useState({
    email: "",
  });

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      }),
    );
  };
  const onSubmit = (e) => {
    e.preventDefault(); // prevents default submit behavior (i.e. reset page)
    setVariable({
      variables: {
        email: `${formData.email}/${process.env.GATSBY_STRAPI_RESTAURANT_ID}`,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(data)) return;

    setEnableSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (_.isEmpty(error)) return;

    // console.log(JSON.stringify(error));
    const message =
      error.graphQLErrors?.[0]?.extensions?.exception?.data?.message?.[0].messages?.[0]
        .message;

    if (message && message !== "This email does not exist.") alert.error(message);
    else setEnableSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ProtectComponentAfterSingin location={location}>
      <Layout seo={seoData} {...layoutProps}>
        <Container fluid className="bg-white">
          <Row>
            <Col
              md={4}
              lg={6}
              className="d-none d-md-flex bg-image"
              style={{
                backgroundImage: `url(${mainPhoto?.url})`,
              }}
            ></Col>
            <Col md={8} lg={6}>
              <div className="login d-flex align-items-center py-5">
                <Container>
                  <Row>
                    <Col
                      md={9}
                      lg={8}
                      className="mx-auto pl-5 pr-5"
                      hidden={enableSection === true}
                    >
                      <h3 className="login-heading mb-1"> {transMsg("forgotPassword")}</h3>
                      <p className="login-heading mb-4">{transMsg("enterYourEmail")}</p>
                    </Col>
                    {/* after continue */}
                    <Col
                      md={9}
                      lg={8}
                      className="mx-auto pl-5 pr-5"
                      hidden={enableSection !== true}
                    >
                      <h3 className="login-heading mb-1"> {transMsg("resetEmailSent")}</h3>
                      <p className="login-heading mb-4">
                        {transMsg("resetEmailSuccessMessage")}
                      </p>
                    </Col>
                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                      <Form onSubmit={onSubmit}>
                        <div className="form-label-group">
                          <Form.Control
                            type="email"
                            id="inputEmail"
                            placeholder={transMsg("emailAddress")}
                            name="email"
                            required
                            onChange={onInputChange}
                          />
                          <Form.Label htmlFor="inputEmail"> {transMsg("email")}</Form.Label>
                        </div>

                        <Button
                          className="btn btn-lg btn-outline-primaryOFF btn-block btn-login text-uppercase font-weight-bold mb-2 round-btn theme-color-back"
                          type="submit"
                        >
                          {enableSection
                            ? transMsg("sendAgain")
                            : transMsg("sendResetEmail")}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </ProtectComponentAfterSingin>
  );
};

export default ForgotPassword;
